"use client"

import { createContext, useContext, type PropsWithChildren } from "react"

import type { InternalRedirects } from "~/data/globals/internal-redirects/serialize"

const InternalRedirectContext = createContext<[InternalRedirects] | null>(null)

export function useInternalRedirects() {
  const context = useContext(InternalRedirectContext) ?? []

  if (!context) {
    throw new Error("useGlobalProduct must be used within a GlobalProductContext")
  }

  return context
}

export function InternalRedirectsProvider({
  children,
  redirects,
}: PropsWithChildren<{ redirects: InternalRedirects }>) {
  return <InternalRedirectContext.Provider value={[redirects]}>{children}</InternalRedirectContext.Provider>
}
